<template>
    <div>
        <label :for="inputId">{{ label }}</label>
        <input
            :id="inputId"
            v-bind="$attrs"
            :type="type"
            @input="$emit('input', $event.target.value)"
        >
    </div>
</template>

<script>
    export default {
        name: 'BaseInput',
        inheritAttrs: false,
        props: {
            inputId: {
                type: String,
                required: true,
            },
            label: {
                type: String,
                default: '',
            },
            type: {
                type: String,
                default: '',
            },
            placeholder: {
                type: String,
                default: '',
            },
        },
    }
</script>

<style lang="scss" scoped>
    label {
        width: 200px;
        display: inline-block;
        text-align: right;
        padding-right: 10px;
    }

    input {
        height: 20px;
        margin-bottom: 10px;
    }
</style>
