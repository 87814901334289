<template>
    <div>
        <h1>Activate Card</h1>
        <form @submit.prevent="activateCard">
            <BaseInput
                label="Activation Code"
                type="text"
                v-model="activationCode"
                placeholder="Activation Code"
                input-id="activationCode"
            />
            <BaseButton type="submit">
                Activate Card
            </BaseButton>
        </form>

        <h4>{{ responseMessage }}</h4>

        <router-link :to="{ name: 'Home' }">
            Back To Home
        </router-link>
    </div>
</template>

<script>
    import BaseButton from '@/components/base/BaseButton'
    import { httpClient } from '@/services/http-client'
    import BaseInput from '../components/BaseInput'

    export default {
        name: 'ActivateCard',
        components: {
            BaseInput,
            BaseButton,
        },
        data: function () {
            return {
                activationCode: '',
                responseMessage: '',
            }
        },
        methods: {
            activateCard: async function () {
                const response = await httpClient.post('/activateAvenCard', {
                    activationOtpCode: this.activationCode,
                })
                this.responseMessage = response.data.error
            },
        },
    }
</script>

<style lang="scss" scoped></style>
